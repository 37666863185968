<template>
  <div class="container">
    <div v-for="(medicineItems, index) in prescription.medicines" :key="index">
      <h1 class="center">{{ userInfo.hospital_name }}处方笺</h1>
      <div class="d-flex align-center mt-2">
        <span>姓名</span>
        <el-input
          style="width: 14rem"
          class="border-line"
          v-model="healthRecord.name"
        ></el-input>
        <span>性别</span>
        <el-input
          class="border-line small"
          :value="healthRecord.gender ? '男' : '女'"
        ></el-input>
        <span>体重</span>
        <el-input
          class="border-line small"
          :value="healthRecord.weight ? healthRecord.weight + 'kg' : ''"
        ></el-input>
        <span>年龄</span>
        <el-input class="border-line small" v-model="healthRecord.y"></el-input
        >岁
        <el-input class="border-line small" v-model="healthRecord.m"></el-input
        >月
        <el-input class="border-line small" v-model="healthRecord.d"></el-input
        >天
      </div>
      <div class="d-flex my-2">
        <span>临床诊断</span>
        <el-input
          class="border-line large"
          v-model="healthRecord.clinical_diagnose"
        ></el-input>
        <el-input
          class="border-line small"
          v-model="healthRecord.inquiry_y"
        ></el-input
        >年
        <el-input
          class="border-line small"
          v-model="healthRecord.inquiry_m"
        ></el-input
        >月
        <el-input
          class="border-line small"
          v-model="healthRecord.inquiry_d"
        ></el-input
        >日
      </div>
      <div class="prescription-R">
        <h2 style="font-size: 48px">R:</h2>
        <div v-if="medicineItems.content && medicineItems.content.length">
          <div v-for="(item, index) in medicineItems.content" :key="index">
            <div class="d-flex">
              <span class="medicine-name">{{ item.name }}</span>
              <span>{{ item.sku }}</span>
            </div>
            <div v-if="item.sig" class="medicine-sig">Sig.{{ item.sig }}</div>
          </div>
          <div>
            ===========================================================================<br />以下内容空白
          </div>
        </div>
      </div>
      <div style="height: 5rem" class="d-flex">
        <div style="width: 50%" class="d-flex">
          <span>医师签名:</span>
          <div v-if="healthRecord.doctor_signature_url">
            <img
              style="width: 200px"
              :src="healthRecord.doctor_signature_url"
            />
          </div>
        </div>
        <span>药师（审核）</span>
        <!-- <el-input style="width:9rem" class="border-line" :value="prescription.pharmacist_name"></el-input> -->
        <div v-if="healthRecord.approval_doctor_signature_url">
          <img
            style="width: 200px"
            :src="healthRecord.approval_doctor_signature_url"
          />
        </div>
      </div>
      <div>
        <div class="d-flex">
          <span>调配</span>
          <el-input
            style="width: 13rem"
            class="border-line"
            :value="prescription.allocation_doctor_name"
          ></el-input>
          <span>核对</span>
          <el-input
            style="width: 13rem"
            class="border-line"
            :value="prescription.approval_doctor_name"
          ></el-input>
          <span>发药</span>
          <el-input
            style="width: 13rem"
            class="border-line"
            :value="prescription.dispensing_doctor_name"
          ></el-input>
        </div>
        <div class="d-flex my-2">
          <span>挂号费</span>
          <el-input
            class="border-line middle"
            :value="
              prescription.registration_fee ? prescription.registration_fee : 0
            "
          ></el-input>
          <span>治疗费</span>
          <el-input
            class="border-line middle"
            :value="prescription.treatment_fee ? prescription.treatment_fee : 0"
          ></el-input>
          <span>药品费</span>
          <el-input
            class="border-line middle"
            :value="prescription.medicine_fee ? prescription.medicine_fee : 0"
          ></el-input>
          <span>合计</span>
          <el-input
            class="border-line middle"
            :value="prescription.amount ? prescription.amount : 0"
          ></el-input>
        </div>
      </div>
      <div
        v-if="index != prescription.medicines.length - 1"
        style="page-break-after: always"
      ></div>
    </div>
  </div>
</template>
<script>
import API from "@/utils/api";
export default {
  data() {
    return {
      userInfo: {},
      prescription: { medicines: {} },
      healthRecord: {},
      today: {
        y: new Date().getFullYear(),
        m: new Date().getMonth() + 1,
        d: new Date().getDate(),
      },
    };
  },

  async mounted() {
    await this.initData();
    this.$forceUpdate();
    setTimeout(() => {
      window.print();
      window.close();
    }, 500);
  },

  methods: {
    async initData() {
      let res = await API.getPrescription(this.$route.params.id);
      if (res.status === 200 && res.result.length) {
        this.prescription = res.result[0];
      }
      let healthRecordRes = await API.getHealthRecord(this.$route.params.hid);
      if (healthRecordRes.status === 200 && healthRecordRes.result.length) {
        this.healthRecord = healthRecordRes.result[0];
        if (this.healthRecord.inquiry_date) {
          this.healthRecord.inquiry_y = new Date(
            this.healthRecord.inquiry_date
          ).getFullYear();
          this.healthRecord.inquiry_m =
            new Date(this.healthRecord.inquiry_date).getMonth() + 1;
          this.healthRecord.inquiry_d = new Date(
            this.healthRecord.inquiry_date
          ).getDate();
        }
        if (this.healthRecord.age) {
          let ages = this.healthRecord.age.toFixed(4).split("."); // e.g., 3.1207 表示 3岁12月07天
          let ageYear = parseInt(ages[0]),
            ageMonths = parseInt(ages[1].substring(0, 2)),
            ageDays = parseInt(ages[1].substring(2, 4));
          this.healthRecord.y = ageYear > 0 ? ageYear : "";
          this.healthRecord.m = ageMonths > 0 ? ageMonths : "";
          this.healthRecord.d = ageDays > 0 ? ageDays : "";
          this.prescription.medicines = [{ content: {} }];
          this.prescription.medicines = this.getMedicines();
        }
      }
      let userRes = await API.getUserInfo();
      if (userRes.status === 200 && userRes.result.length)
        this.userInfo = userRes.result[0];
    },
    getMedicines() {
      let medicineContent = [];
      const pageSize = 10;
      let pageIndex = 1;
      let frequencyMapping = {
        0.5: "两日一次",
        1: "一日一次",
        2: "一日两次",
        3: "一日三次",
        10: "半月一次",
        20: "一月一次",
        30: "三月一次",
        "-1": "必要时",
        9: "自备",
      };
      let watFrequencyMapping = {
        0.5: '48小时一次',
        0.3: '72小时一次',
        60: '必要时'
      }
      if (this.healthRecord.clinical_process) {
        let medicineList = this.healthRecord.clinical_process.split("\n");
        let normalList = [],
          watList = [],
          otherList = [];
        if (medicineList.length) {
          medicineList.forEach((item) => {
            console.log({ item });
            if (item) {
              let category = item.split(".")[0].trim().split(" ")[0];
              let name = item.split(".")[0].trim().split(" ")[1];
              let regUnit = /\[(.*?)\]/g,
                regNumber = /\{(.*?)\}/g,
                units = [],
                result = null,
                numberUnits = [];
              do {
                result = regUnit.exec(item);
                result && units.push(result[1]);
              } while (result);
              do {
                result = regNumber.exec(item);
                result && numberUnits.push(result[1]);
              } while (result);
              if (category === "普通药品") {
                normalList.push({
                  medicine: {
                    name: name,
                    sku: `${numberUnits[1]} × ${numberUnits[2]}${units[0]}`,
                    sig: `${numberUnits[4]}${units[1]}              ${
                      frequencyMapping[Number(numberUnits[3])]
                        ? frequencyMapping[Number(numberUnits[3])]
                        : ""
                    }    ${units[2] ? units[2] : ""} `,
                  },
                  weight: 2,
                });
              } else if (category === "沃爱贴") {
                watList.push({
                  medicine: {
                    name: name,
                    sku:
                      category === "医疗器械"
                        ? `${numberUnits[1]} × ${numberUnits[2]}${units[0]}`
                        : `${numberUnits[2]}${units[0]}`,
                    sig: `${units[1] || ''} ${units[1] ? units[0] == '瓶' ? '喷' : '贴' : ''} ${(watFrequencyMapping[Number(units[2])] || '') + ' ' + (units[3] || '')}`
                  },
                  weight: 2
                });
              } else {
                otherList.push({
                  medicine: {
                    name: name,
                    sku:
                      category === "医疗器械"
                        ? `${numberUnits[1]} × ${numberUnits[2]}${units[0]}`
                        : `${numberUnits[2]}${units[0]}`,
                  },
                  weight: 1,
                });
              }
            }
          });
          let counter = 0;
          let onePageMedicines = [];
          let mixMedicineList = normalList.concat(otherList);
          for (let i = 0; i < mixMedicineList.length; i++) {
            counter += mixMedicineList[i].weight;
            onePageMedicines.push(mixMedicineList[i].medicine);
            if (
              counter >= pageSize * pageIndex ||
              i === mixMedicineList.length - 1
            ) {
              medicineContent.push({
                page: pageIndex++,
                content: onePageMedicines,
              });
              onePageMedicines = [];
            }
          }
          counter = 0;

          for (let i = 0; i < watList.length; i++) {
            counter += watList[i].weight;
            onePageMedicines.push(watList[i].medicine);
            if (counter >= pageSize * pageIndex || i === watList.length - 1) {
              medicineContent.push({
                page: pageIndex++,
                content: onePageMedicines,
              });
              onePageMedicines = [];
            }
          }
        }
        return medicineContent;
      } else {
        return [{ content: {} }];
      }
    },
  },
};
</script>
<style>
* {
  color: #000;
  font-size: 20px;
  font-family: "宋体";
}
.center {
  text-align: center;
}
.el-input {
  font-size: 20px;
}
.border-line input {
  border: 0px;
  border-radius: 0px;
  padding-right: 0px;
  border-bottom: 1px solid black;
  height: 20px;
}
.large {
  width: 26rem;
}
.middle {
  width: 8.5rem;
}
.small {
  width: 4rem;
}
.medicine-name {
  width: 12rem;
}
.medicine-sig {
  margin-left: 20rem;
}
.prescription-R {
  height: 900px;
}
.container {
  width: 1200px;
}
.border-line input {
  height: 30px;
}
</style>